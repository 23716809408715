import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "link",
      "style": {
        "position": "relative"
      }
    }}>{`Link`}</h1>
    <p>{`Links are used primarily as a navigational element. Links may also change what or how data is displayed (E.g.: view more, show all).`}</p>
    <h2 {...{
      "id": "best-practices",
      "style": {
        "position": "relative"
      }
    }}>{`Best practices`}</h2>
    <ul>
      <li parentName="ul">{`Links should be one word or short phrases.`}</li>
      <li parentName="ul">{`Text should be consistent with the title of the intended destination. Avoid labels like "Click here", "Here".`}</li>
      <li parentName="ul">{`If the action taken by the user will change or manipulate data, use a `}<a parentName="li" {...{
          "href": "/components/button"
        }}>{`Button`}</a>{`.`}</li>
    </ul>
    <Demo src='pages/components/link/Link' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      